// import Vue from 'vue'
import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Watch } from "vue-property-decorator";
import gridComponentBase from "../../components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import apiFile from "@/services/apiFile";
import moment from "moment";

const _ENDPONT = api.endpoint.PRATICHE_XML;

@Component({})
export default class praticheXMLPage extends gridComponentBase {


	txtXML: string = "";
	txtURL: string = "";
	txtRespXML: string = "";

	testDeleteCmd: string = '<envelope><header><user>nomeUtente</user><password>password</password><version>1.0.0</version><timestamp>20200430150000</timestamp></header><query type="delete_service" category="insurance" confirm="false"><reference code="2023011360016801"></reference></query></envelope>'
	testModifyCmd: string = '<envelope><header><user>nomeUtente</user><password>password</password><version>1.0.0</version><timestamp>20200430150000</timestamp></header><query type="modify" category="insurance"><product code="564"/><reference code="test"/><startdate value="20230221"/><enddate value="20230227"/><nationality code="ITA"/><destination code="ITA"/><travel_cost value="10"/><customer_code code="customer"/><paxes number="1" /><policyholders><policyholder><name>test xml</name><surname>test xml</surname><tax_code>tstxml04E14C320X</tax_code></policyholder></policyholders></query></envelope>'
	testPreBuyCmd: string = '<envelope><header><user>nomeUtente</user><password>password</password><version>1.0.0</version><timestamp>20200430150000</timestamp></header><query type="pre_buy" category="insurance"><product code="564"/><reference code="test"/><startdate value="20230221"/><enddate value="20230227"/><nationality code="ITA"/><destination code="ITA"/><travel_cost value="10"/><customer_code code="customer"/><paxes number="1" /></query></envelope>'
	testProductDetailsCmd: string = '<envelope><header><user>nomeUtente</user><password>password</password><version>1.0.0</version><timestamp>20200430150000</timestamp></header><query type="product_details" category="insurance"><product code="307"/></query></envelope>'

	created() {
		this.txtXML = "";
	}

	mounted() {
	}

	creaComando(tipo){

		var msg = "";
		switch(tipo){
			case 'buy':
				var info = this.creaInfo();
				msg = this.creaMessaggio(info);
				break;
			case 'product_details':
				msg = this.testProductDetailsCmd;
				break;
			case 'delete_service':
				msg = this.testDeleteCmd;
				break;
			case 'modify':
				msg = this.testModifyCmd;
				break;
			case 'pre_buy':
				msg = this.testPreBuyCmd;
				break;
		}
		
		this.txtXML = this.prettifyXml(msg);
	}

	creaInfo(){
		var dataDa = moment(),
			dataA = moment().add(1, "days");

		var info =  {
			Codice: "83",
			StartDate: dataDa.format("YYYYMMDD"),
			EndDate: dataA.format("YYYYMMDD"),
			Costo: 10,
			Clienti: [
				{
					Nome: "test xml",
					Cognome: "test xml",
					CodiceFiscale: "tstxml04E14C320X"
				}
			]

		}

        return info;
	}

	creaMessaggio(info){

        var user = "nomeUtente";
        var password = "password";
        var customer = "customer";
      

		var msg = "<envelope>\n";
		msg += "<header>\n";
		msg += `<user>${user}</user>\n`;
		msg += `<password>${password}</password>\n`;
		msg += "<version>1.0.0</version>\n";
		msg += `<timestamp>${moment().format("YYYMMDDHHmmss")}</timestamp>\n`;
		msg += "</header>\n";
		msg += "<query type=\"buy\" category=\"insurance\">\n";
		msg += `<product code=\"${info.Codice}\"/>\n`;
		msg += `<startdate value=\"${info.StartDate}\"/>\n`;
		msg += `<enddate value=\"${info.EndDate}\"/>\n`;
		msg += "<nationality code=\"ITA\"/>\n";
		msg += "<destination code=\"ITA\"/>\n";
		msg += `<travel_cost value=\"${info.Costo}\"/>\n`;
		msg += `<customer code=\"${customer}\"/>\n`;
		msg += "<policyholders>\n";

		for (var i = 0; i < info.Clienti.length; i++){
				
			msg += "<policyholder>\n";
			msg += `<name>${info.Clienti[i].Nome}</name>\n`;
			msg += `<surname>${info.Clienti[i].Cognome}</surname>\n`;
			msg += `<tax_code>${info.Clienti[i].CodiceFiscale}</tax_code>\n`;
			msg += "</policyholder>\n";
		}


		msg += "</policyholders>\n";

		// msg += "<emails>\n";

		// msg += "<email>\n";
		// msg += "<emailAddress></emailAddress>\n";
		// msg += "<templateName>xml-tmpl-test01</templateName>\n";
		// msg += "</email>\n";

		// msg += "</emails>\n";

		msg += "</query>\n";
		msg += "</envelope>\n";



		return msg;
	}

	inviaMessaggio(){
		this.richiamaServizio(this.txtXML);
	}

	richiamaServizio(comando){
		var self = this;
		api.postXML(_ENDPONT,comando).then(res =>{
			self.txtRespXML = self.prettifyXml( res as any) ;
		}).catch(err =>{
			alert ("Errore durante l'esecuzione del comando" + (err ? "\n" + err : ""))
		});

	}

	cancellaPratica(){
		var codiceDaCancellare = this.txtCodiceDaCancellare;

		if( !codiceDaCancellare)
			return;

		var user = "";
		var password = "";

		var msg = "<envelope>\n";
		msg += "<header>\n";
		msg += `<user>${user}</user>\n`;
		msg += `<password>${password}</password>\n`;
		msg += "<version>1.0.0</version>\n";
		msg += `<timestamp>${moment().format("YYYMMDDHHmmss")}</timestamp>\n`;
		msg += "</header>\n";
		msg += "<query type=\"delete_service\" category=\"insurance\">\n";
		msg += `<reference code=\"${codiceDaCancellare}\"/>\n`;
		msg += "</query>\n";
		msg += "</envelope>\n";

        this.richiamaServizio(msg);
	}

	prettifyXml (sourceXml){
		if( !sourceXml)
			return "";

		var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
		var xsltDoc = new DOMParser().parseFromString([
			// describes how we want to modify the XML - indent everything
			'<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
			'  <xsl:strip-space elements="*"/>',
			'  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
			'    <xsl:value-of select="normalize-space(.)"/>',
			'  </xsl:template>',
			'  <xsl:template match="node()|@*">',
			'    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
			'  </xsl:template>',
			'  <xsl:output indent="yes"/>',
			'</xsl:stylesheet>',
		].join('\n'), 'application/xml');
	
		var xsltProcessor = new XSLTProcessor();    
		xsltProcessor.importStylesheet(xsltDoc);
		var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
		var resultXml = new XMLSerializer().serializeToString(resultDoc);
		return resultXml;
	};
}